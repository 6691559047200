import {
  Button,
  CardBody,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import React, { useMemo, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { BsThreeDots } from "react-icons/bs";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  useForm,
} from "react-hook-form";
import { CustomerType } from "../../../types/customer";
import { useHistory } from "react-router-dom";
import { GET_ALL_CLIENT } from "../../../GraphQl/queries";
import { useQuery } from "@apollo/client";

function DashboardOverview() {
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<CustomerType>();

  const [yeardate, setYearDate] = useState("");

  function getOneYearLaterFromDate(dateStr: any) {
    const selectedDate = new Date(dateStr);
    selectedDate.setFullYear(selectedDate.getFullYear() + 1);

    const year = selectedDate.getFullYear();
    let month: number | string = selectedDate.getMonth() + 1;
    let day: number | string = selectedDate.getDate();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    setYearDate(`${year}-${month}-${day}`);

    return `${year}-${month}-${day}`;
  }

  const history = useHistory();
  let PAGE_SIZE = 20;

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    };
  }, [selectedRow]);
  console.log(defaultValues.start_date);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CustomerType>({
    defaultValues: {
      ...defaultValues,
      gps_address: defaultValues.gps_address
        ? defaultValues.gps_address
        : defaultValues.house_street,
      email: defaultValues.email ? defaultValues.email : "N/A",
    },
  });

  const { data, loading, refetch } = useQuery(GET_ALL_CLIENT);

  const handleSearch = (e: any) => {
    let DUMMYDATA = [{}, {}, {}];
    setSearchText(e.target.value);
    if (e.target.value?.trim()?.length > 1) {
      setSearchResult(DUMMYDATA);
    } else {
      setSearchResult([]);
    }
    setActivePage(1);
  };

  const filterRentals = (data: CustomerType) => {
    const isTextMatch =
      data?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.first_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.house_street?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.last_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.phone_number?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.gps_address?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.house_street?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.location?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      searchText;

    return isTextMatch;
  };

  const paginatedData = data?.pa_customers
    ?.filter(filterRentals)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  // Function to get today's date in YYYY-MM-DD format
  function getTodayDate(): string {
    const today: Date = new Date();
    const year: number = today.getFullYear();
    let month: number | string = today.getMonth() + 1;
    let day: number | string = today.getDate();

    // Add leading zero for single-digit months/days
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  const onSubmit: SubmitHandler<CustomerType> = (data) => {
    if (localStorage.getItem("selected-data")) {
      history.push("/dashboard/beneficiary");
      window.location.reload();
    } else {
      let defaultData = JSON.stringify({
        start_date: data.policy_start_date,
        duration: "",
        premium: "50",
        capital_sum_assured: "10,000.00",
        weekly_benefit_per_week: "190.00",
        medical_expense_limit: "1,000.00",
        currency: "",
        end_date: yeardate,
        customer_details_metadata: {
          beneficiaries: [],
          ...data,
        },
      });
      localStorage.setItem("personal-details", defaultData);
      refetch();
      reset();
      history.push("/dashboard/beneficiary");
      window.location.reload();
    }
  };

  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key as keyof typeof defaultValues, value);
      });
    }
  }, [defaultValues]);

  React.useEffect(() => {
    const storageData = localStorage.getItem("selected-data");

    if (storageData) {
      Object.entries(JSON.parse(storageData)).forEach(([key, value]) => {
        let $data: CustomerType = storageData ? JSON.parse(storageData) : null;

        setValue(key as keyof typeof $data, value as any);
      });
      setSelectedRow(JSON.parse(storageData));
      setSearchResult([]);
    }
  }, []);

  const resetForm = () => {
    localStorage.removeItem("quote_number");
    localStorage.removeItem("personal-details");
    localStorage.removeItem("selected-data");
    setSearchText("");
    reset();
  };

  const onRowClick = (item: CustomerType) => {
    setSelectedRow(item);
    setSearchResult([]);
  };

  return (
    <>
      <CustomHeader title="Clients Details" />

      <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
        <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
          <div className="w-full mr-3">
            <Input
              label="Search existing client"
              value={searchText}
              onChange={handleSearch}
              icon={<i className="fas fa-search text-base"></i>}
            />
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="mt-10">
          {searchResult?.length > 0 ? (
            <>
              <CardBody className="overflow-x-scroll px-0  rounded-3xl bg-white">
                <table className="w-full min-w-max table-auto">
                  <thead>
                    <tr>
                      {[
                        "client name",
                        "email",
                        "phone",
                        "school",
                        "address",
                        // "policy start date",
                        // "policy end date",
                        "actions",
                        // "",
                      ].map((head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((item: CustomerType, index: number) => {
                      const isLast = index === paginatedData?.length - 1;

                      const classes = isLast
                        ? "py-2 px-3  text-center"
                        : "py-2 px-3 border-b border-blue-gray-50 text-center";

                      return (
                        <tr
                          onClick={() => onRowClick(item)}
                          className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className=" font-normal text-xs capitalize"
                            >
                              {`${item?.first_name} ${item?.last_name}`}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.email}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.first_name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.school}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.house_street}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Menu>
                              <MenuHandler>
                                <Button size="sm" variant="text" color="white">
                                  <BsThreeDots color="black" size={20} />
                                </Button>
                              </MenuHandler>
                              <MenuList>
                                <MenuItem
                                  onClick={() => onRowClick(item)}
                                  className="text-brand-blue text-base font-medium"
                                >
                                  View
                                </MenuItem>
                                {/* <MenuItem
                                  onClick={() => onRowClick(item)}
                                  className="text-brand-blue text-base font-medium"
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setSelectedRow(item);
                                    setDeleteModal(true);
                                  }}
                                  className="text-brand-red text-base font-medium"
                                >
                                  Delete
                                </MenuItem> */}
                              </MenuList>
                            </Menu>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {!paginatedData && !loading && (
                  <div className="mt-10 flex items-center justify-center">
                    <Typography variant="h6">No data found</Typography>
                  </div>
                )}
              </CardBody>

              {!data?.pa_customers && !loading && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-1o h-10" />
                </div>
              )}
            </>
          ) : (
            <AddClientForm
              register={register}
              errors={errors}
              reset={resetForm}
              yeardate={yeardate}
              getOneYearLaterFromDate={getOneYearLaterFromDate}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
    </>
  );
}

const AddClientForm = ({
  register,
  errors,
  reset,
  onSubmit,
  handleSubmit,
  yeardate,
  getOneYearLaterFromDate,
}: {
  register: UseFormRegister<CustomerType>;
  errors: FieldErrors<CustomerType>;
  reset: () => void;
  onSubmit: SubmitHandler<CustomerType>;
  handleSubmit: UseFormHandleSubmit<CustomerType, undefined>;
  yeardate: string;
  getOneYearLaterFromDate: (datestr: string) => string;
}) => {
  const schools = [
    "University of Professional Studies(UPSA)",
    "Kwame Nkrumah University of Science and Technology(KNUST)",
    "University of Health and Allied Science (UHAS)",
    "Takoradi Technical Institue(TTI)",
    "University of Energy and Natural Resources (UENR)",
    "University of Ghana(LEGON)",
    "Takoradi Technical University(TTU)",
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardBody className="p-5 rounded-3xl bg-white">
        <div>
          <div className="mb-6 flex capitalize flex-col md:flex-row lg:flex-row gap-6">
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm"
              >
                first name
              </Typography>
              <Input
                error={!!errors?.first_name}
                size="md"
                disabled={localStorage.getItem("selected-data") ? true : false}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                {...register("first_name", { required: true })}
              />
            </div>
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm"
              >
                Surname
              </Typography>
              <Input
                error={!!errors?.last_name}
                size="md"
                disabled={localStorage.getItem("selected-data") ? true : false}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                {...register("last_name", { required: true })}
              />
            </div>
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm"
              >
                school name
              </Typography>
              <select
                disabled={localStorage.getItem("selected-data") ? true : false}
                id="school"
                className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                {...register("school", { required: true })}
              >
                <option label="Select school" value=""></option>
                {schools?.map((item) => (
                  <option label={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-6 flex capitalize flex-col md:flex-row lg:flex-row gap-6">
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm"
              >
                Phone number
              </Typography>
              <Input
                error={!!errors?.phone_number}
                size="md"
                disabled={localStorage.getItem("selected-data") ? true : false}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                {...register("phone_number", {
                  required: true,
                  pattern: {
                    value: /^(?:\d{10}|\d{10}\/\d{10})$/,
                    message: "Invalid phone number",
                  },
                })}
              />
              {errors?.phone_number && (
                <span className="text-xs text-brand-red">
                  {errors.phone_number.message}
                </span>
              )}
            </div>
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm"
              >
                Email
              </Typography>
              <Input
                error={!!errors?.email}
                size="md"
                disabled={localStorage.getItem("selected-data") ? true : false}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                {...register("email", { required: false })}
              />
            </div>
          </div>
          <div className="mb-6 flex capitalize flex-col md:flex-row lg:flex-row gap-6">
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm  normal-case"
              >
              Date of Birth
              </Typography>
              <Input
                error={!!errors?.dob}
                size="md"
                disabled={localStorage.getItem("selected-data") ? true : false}
                type="date"
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                {...register("dob", { required: false })}
              />
            </div>
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm"
              >
                residential address/street name
              </Typography>
              <Input
                error={!!errors?.house_street}
                size="md"
                disabled={localStorage.getItem("selected-data") ? true : false}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                {...register("house_street", { required: true })}
              />
            </div>
          </div>
          <div className="mb-6 flex capitalize flex-col md:flex-row lg:flex-row gap-6">
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm"
              >
                location
              </Typography>
              <Input
                error={!!errors?.location}
                size="md"
                disabled={localStorage.getItem("selected-data") ? true : false}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                {...register("location", { required: true })}
              />
            </div>
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm"
              >
                GPS address
              </Typography>
              <Input
                error={!!errors?.gps_address}
                size="md"
                disabled={localStorage.getItem("selected-data") ? true : false}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                {...register("gps_address", { required: false })}
              />
            </div>
            <div className="w-full">
              <Typography
                variant="h6"
                color="blue-gray"
                className="mb-2 font-semibold text-sm"
              >
                Policy start date
              </Typography>
              <Input
                error={!!errors?.policy_start_date}
                size="md"
                disabled={localStorage.getItem("selected-data") ? true : false}
                // onChange={handleStartDateChange}
                type="date"
                defaultValue={"11/22/33"}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                {...register("policy_start_date", {
                  required: true,
                  onChange: (e) => getOneYearLaterFromDate(e.target.value),
                })}
              />
              {yeardate && (
                <p className="text-xs mt-4 text-gray-600 normal-case">
                  The policy will end on{" "}
                  <span className="font-bold text-black">{yeardate}</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </CardBody>

      <div className="mt-4 flex gap-3 items-end justify-end">
        <Button onClick={() => reset()} className="bg-brand-red">
          Cancel
        </Button>
        <Button type="submit" className="bg-brand-blue">
          Next
        </Button>
      </div>
    </form>
  );
};

export default DashboardOverview;
