import React, { useState } from "react";
import { DashboardNavbar } from "../headers/DashboardHeader";
import { Route, Switch } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";
import { privateRoutes } from "../../routes";
import { SidebarContent } from "../Sidebar/SidebarContent";
import { MobileSidebar } from "../Sidebar/MobileSidebar";

const DashboardContent = () => {
  return (
    <>
      <div className="flex bg-[#2c5bb1] h-full w-[100vw]">
        <div className="w-80 h-screen hidden md:block lg:blcok">
          <SidebarContent />
        </div>
        <div className="m-0 md:m-2 lg:m-5 w-[100vw]">
          <div className="dashboard-container overflow-y-scroll h-[95vh] px-4 lg:px-5 bg-gray-100 rounded-3xl transition-all lg:rounded-3xl md:rounded-3xl">
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                {privateRoutes?.map((route, index) => (
                  <Route
                    path={route.layout + route.path}
                    component={route.component}
                    exact={route.exact}
                    key={index}
                  />
                ))}
              </Switch>
            </React.Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardContent;
