import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import { ReactNode } from "react";

interface ModalProps {
  onClose: () => void;
  title?: string;
  size?: string | "md" | any;
  children?: ReactNode | null;
  open: boolean;
}

export default function CustomModal({
  onClose,
  open,
  children,
  size,
  title,
}: ModalProps) {
  return (
    <Dialog open={open} handler={onClose} size={size} className="">
      {title && <DialogHeader>{title}</DialogHeader>}
      <DialogBody>{children}</DialogBody>
    </Dialog>
  );
}
