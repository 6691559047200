import {
  Button,
  CardBody,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import logo from "../../assets/logo.png";
import { CustomModal } from "../../components";
import { BASEURL } from "../../util";
import { QuoteType } from "../../types/quote";

const TABLE_HEAD = ["Reciept name", "date created", ""];

const CustomReceiptView = () => {
  const [loading, setLoading] = useState(true);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [openQuotationModal, setQuotationModal] = useState(false);
  const [quoteDetail, setQuoteDetail] = useState<QuoteType>();

  const ReceiptData = [
    {
      reciept_name: "Quotation Information",
      createdAt: moment().format("LLL"),
      onClick: () => {
        setQuotationModal(true);
      },
    },
    {
      reciept_name: "Policy reciept",
      createdAt: moment().format("LLL"),
      onClick: () => {
        setOpenPolicyModal(true);
      },
    },
  ];

  const fetchData = async () => {
    try {
      const res = await fetch(`${BASEURL}/get-pa-quote-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quote_number: localStorage.getItem("quote_number"),
        }),
      });

      const req = await res.json();

      if (res.ok) {
        toast.success(req.message);
        setQuoteDetail(req.data);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message ?? "Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bg-gray-100 h-screen px-2 md:px-4 lg:px-6 ">
      <div className="max-w-5xl mx-auto ">
        <div className="flex items-center justify-center pt-7">
          <img src={logo} className="w-32" />
        </div>
        <CustomModal
          open={openQuotationModal}
          onClose={() => setQuotationModal(false)}
          children={
            <QuotationDetailComponent {...(quoteDetail as QuoteType)} />
          }
          size={"md"}
        />

        <CustomModal
          open={openPolicyModal}
          onClose={() => setOpenPolicyModal(false)}
          children={<PolicyDetailComponent {...(quoteDetail as QuoteType)} />}
          size={"md"}
        />
        <div className="w-full py-5">
          <div className="flex flex-row justify-between items-center gap-3 w-full">
            <Typography className="font-semibold text-lg lg:text-xl">
              Receipts
            </Typography>
          </div>
        </div>
        <div className="mt-10">
          <CardBody className="overflow-x-scroll px-0  rounded-3xl bg-white">
            <table className="w-full min-w-max table-auto">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {quoteDetail
                  ? ReceiptData?.map((item: any, index: number) => {
                      const isLast = index === ReceiptData?.length - 1;
                      const classes = isLast
                        ? "px-2 py-1.5  text-center"
                        : "px-2 py-1.5 border-b border-blue-gray-50 text-center";

                      return (
                        <tr
                          key={item?.reciept_name + index.toString()}
                          className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.reciept_name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              {item?.createdAt}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Tooltip content="View">
                              <Button
                                size="sm"
                                onClick={item.onClick}
                                variant="filled"
                                className="bg-brand-blue font-normal text-[9px] capitalize px-5"
                              >
                                View more
                              </Button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>

            {!quoteDetail && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography variant="h6">No data found</Typography>
              </div>
            )}
          </CardBody>
          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PolicyDetailComponent = (props: QuoteType | undefined) => {
  return (
    <div>
      <div className="flex items-center justify-center">
        <img src={logo} className="w-28" />
      </div>

      <div className="mt-4">
        <h1 className="text-lg font-bold text-black capitalize text-center">
          Policy information
        </h1>
        <div className="mt-4">
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy Number
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {props?.quotation_number}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy Holder Name
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {`${props?.pa_customer?.first_name} ${props?.pa_customer?.last_name}`}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              School Name
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {props?.pa_customer?.school}
            </h1>
          </div>
          <hr />
          <div className="my-3">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Summary of benefits
              </h1>
            </div>
            <div className="ml-4">
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red   capitalize ">
                  Capital sum assured
                </h1>
                <h1 className="text-xs text-brand-blue capitalize ">
                  {props?.capital_sum_assured}
                </h1>
              </div>
              <hr />
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red  capitalize ">
                  TD Weekly Benefit
                </h1>
                <h1 className="text-xs text-brand-blue   capitalize ">
                  {props?.weekly_benefit_per_week}
                </h1>
              </div>
              <hr />
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red capitalize ">
                  Medical Expense Limit
                </h1>
                <h1 className="text-xs text-brand-blue capitalize ">
                  {props?.medical_expense_limit}
                </h1>
              </div>
              <hr />
            </div>
          </div>
          <div className="my-3">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Beneficaries
              </h1>
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Percent share
              </h1>
            </div>
            {props?.pa_customer?.beneficiaries?.map((item) => (
              <div key={item?.email}>
                <hr />
                <div className="ml-4">
                  <div className="my-3 flex flex-row items-center justify-between">
                    <h1 className="text-xs  text-brand-red   capitalize ">
                      {`${item?.first_name} ${item?.last_name}`}
                    </h1>
                    <h1 className="text-xs text-brand-blue capitalize ">
                      {item?.percentage_share}%
                    </h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy start date{" "}
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {moment(props?.start_date).format("LLL")}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy End date{" "}
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {moment(props?.end_date).format("LLL")}
            </h1>
          </div>
          <hr />

          <div className="mt-7">
            <Button className="w-full bg-red-100 text-brand-red py-3 text-sm">
              premium paid: GH {props?.premium_amount}
            </Button>
          </div>

          <div className="mt-2 flex items-center justify-center">
            <span className="text-[10px] text-center font-medium text-brand-red">
              Important Notice: Please refer to the standard policy for full
              terms and conditions
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuotationDetailComponent = (props: QuoteType | undefined) => {
  return (
    <>
      <div className="flex items-center justify-center">
        <img src={logo} className="w-28" />
      </div>

      <div className="mt-4">
        <h1 className="text-lg font-bold text-black capitalize text-center">
          Quotation information
        </h1>
        <div className="mt-4">
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              type of cover
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {props?.type_of_cover}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Quotation number
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {props?.quotation_number}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Client name
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {`${props?.pa_customer?.first_name} ${props?.pa_customer?.last_name}`}
            </h1>
          </div>
          <hr />

          <div className="my-3">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Summary of benefits
              </h1>
            </div>
            <div className="ml-4">
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red   capitalize ">
                  Capital sum assured
                </h1>
                <h1 className="text-xs text-brand-blue capitalize ">
                  {props?.capital_sum_assured}
                </h1>
              </div>
              <hr />
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red  capitalize ">
                  TD Weekly Benefit
                </h1>
                <h1 className="text-xs text-brand-blue   capitalize ">
                  {props?.weekly_benefit_per_week}
                </h1>
              </div>
              <hr />
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red capitalize ">
                  Medical Expense Limit
                </h1>
                <h1 className="text-xs text-brand-blue capitalize ">
                  {props?.medical_expense_limit}
                </h1>
              </div>
            </div>
          </div>
          <hr />
          <div className="my-3">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Beneficaries
              </h1>
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Percent share
              </h1>
            </div>
            {props?.pa_customer?.beneficiaries?.map((item) => (
              <div key={item?.email}>
                <hr />
                <div className="ml-4">
                  <div className="my-3 flex flex-row items-center justify-between">
                    <h1 className="text-xs  text-brand-red   capitalize ">
                      {`${item?.first_name} ${item?.last_name}`}
                    </h1>
                    <h1 className="text-xs text-brand-blue capitalize ">
                      {item?.percentage_share}%
                    </h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy start date{" "}
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {moment(props?.start_date).format("LLL")}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy End date{" "}
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {moment(props?.end_date).format("LLL")}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Number of months
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              N/a
            </h1>
          </div>

          <div className="mt-7">
            <Button className="w-full bg-red-100 text-brand-red py-3 text-sm">
              Premium payable: GH {props?.premium_amount}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomReceiptView;
