import React, { useState } from "react";

const useDrawer = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const closeDrawer = () => setOpen(false);

  return {
    open,
    toggleDrawer,
    setOpen,
    closeDrawer,
  };
};

export default useDrawer;
