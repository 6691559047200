import {
  BeneficiaryView,
  DashboardOverview,
  RecieptView,
  ReportView,
} from "./pages";

export const privateRoutes = [
  {
    path: "/",
    component: DashboardOverview,
    name: "dashboard",
    exact: true,
    layout: "/dashboard",
  },
  {
    path: "/beneficiary",
    exact: false,
    component: BeneficiaryView,
    name: "games",
    layout: "/dashboard",
  },
  {
    path: "/reports",
    exact: false,
    component: ReportView,
    name: "reports",
    layout: "/dashboard",
  },
  {
    path: "/reciept",
    exact: false,
    component: RecieptView,
    name: "reciept",
    layout: "/dashboard",
  },
];
