import { Card, List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import { NavLink, Link, useHistory } from "react-router-dom";
import Logo from "../../assets/logo_white.png";
import { toast } from "react-toastify";
import { BASEURL } from "../../util";
import { IoReceiptSharp } from "react-icons/io5";
import { FaUsers } from "react-icons/fa6";
import { MdSpaceDashboard } from "react-icons/md";

export function SidebarContent() {
  const history = useHistory();

  const handleLogout = async () => {
    try {
      localStorage.removeItem("quote_number");
      localStorage.removeItem("personal-details");
      localStorage.removeItem("selected-data");
      history?.push("/report");
      window.location.reload();
    } catch (error) {}
  };

  return (
    <Card className=" h-full bg-[#2c5bb1] shadow-none w-full max-w-[20rem] rounded-none  p-4">
      <div className="mb-1 p-4">
        <h1 className="text-center font-extrabold text-white text-lg md:text-xl lg:text-2xl">
          New Business
        </h1>
      </div>
      <List>
        <a href={"/dashboard"}>
          <ListItem
            className={`${
              history?.location?.pathname === "/dashboard" && "bg-brand-red"
            } text-sm hover:bg-brand-red hover:text-white text-white capitalize`}
          >
            <ListItemPrefix>
              <MdSpaceDashboard />
            </ListItemPrefix>
            Client Details
          </ListItem>
        </a>
        <a href={"/dashboard/beneficiary"}>
          <ListItem
            className={`${
              history?.location?.pathname === "/dashboard/beneficiary" &&
              "bg-brand-red"
            } mt-2 hover:bg-brand-red hover:text-white text-white capitalize text-base`}
          >
            <ListItemPrefix>
              <FaUsers />
            </ListItemPrefix>
            Beneficiaries
          </ListItem>
        </a>
        <a href={"/dashboard/reciept"}>
          <ListItem
            className={`${
              history?.location?.pathname === "/dashboard/reciept" &&
              "bg-brand-red"
            } mt-2 hover:bg-brand-red hover:text-white text-white capitalize text-base`}
          >
            <ListItemPrefix>
              <IoReceiptSharp />
            </ListItemPrefix>
            reciepts
          </ListItem>
        </a>

        <div className="absolute w-full bottom-7 ">
          <div className="items-start justify-start">
            <div className="h-0.5 w-fit bg-white rounded-md" />
            <ListItem
              onClick={handleLogout}
              className="hover:bg-transparent hover:text-brand-primary text-white text-sm w-full"
            >
              <ListItemPrefix>
                <i className="fa fa-sign-out text-sm" aria-hidden="true"></i>
              </ListItemPrefix>
              Back to home
            </ListItem>
          </div>
        </div>
      </List>
    </Card>
  );
}
