import React, { useState, useEffect, memo } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  CardHeader,
  IconButton,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { BiLogOut, BiPlus, BiReset, BiSearch } from "react-icons/bi";
import Logo from "../../../assets/logo.png";
import { BsThreeDots } from "react-icons/bs";
import { CSVLink, CSVDownload } from "react-csv";
import { useHistory } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { useQuery } from "@apollo/client";
import { GET_ALL_POLICY } from "../../../GraphQl/queries";
import { ReportType } from "../../../types/report";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { CustomerType } from "../../../types/customer";
import moment from "moment";

const ReportView = () => {
  const [schools, setSchool] = useState<string[]>([]);
  const [filters, setFilter] = useState("");
  const history = useHistory();

  const [selectedRow, setSelectedRow] = useState<CustomerType>();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [generating, setGenerating] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState<ReportType[]>([]);
  const [paginatedData, setPaginatedData] = useState<ReportType[]>([]);
  const [activePage, setActivePage] = useState(1);

  const PAGE_SIZE = 10;

  const { data, loading, refetch } = useQuery(GET_ALL_POLICY);

  const filterData = (q: ReportType) => {
    const isTextMatch =
      q?.currency?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      q?.pa_customer?.first_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      q?.pa_customer?.last_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      q?.pa_customer?.email
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      q?.pa_customer?.house_street
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      q?.pa_customer?.gps_address
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      q?.pa_customer?.location
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      q?.pa_customer?.phone_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      q?.duration?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      q?.medical_expense_limit
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      q?.premium_amount?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      q?.quotation_number?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      q?.type_of_cover?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      q?.weekly_benefit_per_week
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());

    return isTextMatch;
  };

  useEffect(() => {
    if (data && data.policy_data) {
      // Filter data based on selected filter and dates
      const filtered = data.policy_data
        .filter(filterData)
        .filter((item: ReportType) =>
          fromDate && toDate
            ? item.pa_customer?.created_at
              ? new Date(item.pa_customer.created_at).getTime() >=
              new Date(fromDate).getTime() &&
              new Date(item.pa_customer.created_at).getTime() <=
              new Date(toDate).getTime()
              : false
            : true
        );

      // Set schools
      const uniqueSchools = Array.from(
        new Set(filtered.map((item: ReportType) => item.pa_customer.school))
      );
      setSchool(uniqueSchools as any);

      // Set filtered data
      setFilteredData(filtered);

      // Paginate data
      const startIndex = (activePage - 1) * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      const paginated = filtered.slice(startIndex, endIndex);
      setPaginatedData(paginated);
    }
  }, [data]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);
    setActivePage(1); // Reset active page to 1 when the filter changes

    if (data && data.policy_data) {
      const filtered = data.policy_data
        .filter((item: ReportType) => {
          // Use the selected filter to determine whether to include the item
          return (
            selectedFilter === "Select School" ||
            item.pa_customer.school === selectedFilter
          );
        })
        .filter((item: ReportType) =>
          fromDate && toDate
            ? item.pa_customer?.created_at
              ? new Date(item.pa_customer.created_at).getTime() >=
              new Date(fromDate).getTime() &&
              new Date(item.pa_customer.created_at).getTime() <=
              new Date(toDate).getTime()
              : false
            : true
        );

      const startIndex = 0;
      const endIndex = PAGE_SIZE;
      const paginated = filtered.slice(startIndex, endIndex);

      setFilteredData(filtered);
      setPaginatedData(paginated);
    }
  };

  const handleSearch = (e: any) => {
    const searchText = e.target.value.toLowerCase();
    setSearchText(searchText);
    setActivePage(1);

    const filtered = data?.policy_data?.filter((q: ReportType) => {
      return (
        q?.currency?.toLowerCase()?.includes(searchText) ||
        q?.pa_customer?.first_name?.toLowerCase()?.includes(searchText) ||
        q?.pa_customer?.last_name?.toLowerCase()?.includes(searchText) ||
        q?.pa_customer?.email?.toLowerCase()?.includes(searchText) ||
        q?.pa_customer?.house_street?.toLowerCase()?.includes(searchText) ||
        q?.pa_customer?.gps_address?.toLowerCase()?.includes(searchText) ||
        q?.pa_customer?.location?.toLowerCase()?.includes(searchText) ||
        q?.pa_customer?.phone_number?.toLowerCase()?.includes(searchText) ||
        q?.duration?.toLowerCase()?.includes(searchText) ||
        q?.medical_expense_limit?.toLowerCase()?.includes(searchText) ||
        q?.premium_amount?.toLowerCase()?.includes(searchText) ||
        q?.quotation_number?.toLowerCase()?.includes(searchText) ||
        q?.type_of_cover?.toLowerCase()?.includes(searchText) ||
        q?.weekly_benefit_per_week?.toLowerCase()?.includes(searchText)
      );
    });

    // Update filtered and paginated data
    setFilteredData(filtered || []);
    setPaginatedData(filtered?.slice(0, PAGE_SIZE) || []);
  };

  const nextPage = () => {
    const totalPages = Math.ceil((filteredData?.length || 0) / PAGE_SIZE);
    if (activePage < totalPages) {
      const startIndex = activePage * PAGE_SIZE;
      const endIndex = (activePage + 1) * PAGE_SIZE;
      const paginated = filteredData?.slice(startIndex, endIndex);
      setActivePage((prevPage) => prevPage + 1);
      setPaginatedData(paginated || []);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      const startIndex = (activePage - 2) * PAGE_SIZE;
      const endIndex = (activePage - 1) * PAGE_SIZE;
      const paginated = filteredData?.slice(startIndex, endIndex);
      setActivePage((prevPage) => prevPage - 1);
      setPaginatedData(paginated || []);
    }
  };

  const generateReport = async () => {
    setGenerating(true);

    try {
      let filteredData: ReportType[] | undefined;

      filteredData = data?.policy_data
        ?.filter(filterData)
        ?.filter((item: ReportType) =>
          fromDate && toDate
            ? item?.pa_customer?.created_at
              ? new Date(item?.pa_customer?.created_at).getTime() >=
              new Date(fromDate).getTime() &&
              new Date(item?.pa_customer?.created_at).getTime() <=
              new Date(toDate).getTime()
              : false
            : true
        );

      const startIndex = (activePage - 1) * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      const paginated = filteredData
        ? filteredData.slice(startIndex, endIndex)
        : [];

      setTimeout(() => {
        setPaginatedData(paginated);
        setGenerating(false);
      }, 2000);
    } catch (error) { }
  };

  const csvLinkData =
    data?.policy_data?.map((item: ReportType) => ({
      "client name": `${item.pa_customer.first_name} ${item.pa_customer.last_name}`,
      email: item.pa_customer.email,
      phone: item.pa_customer.phone_number,
      school: item.pa_customer.school,
      address: item.pa_customer.gps_address,
      "policy start date": item.start_date,
      "policy end date": item.end_date,
      actions: "view",
    })) || [];

  const csvLink = {
    filename: `${filters}.csv`,
    headers: [
      "client name",
      // "email",
      "phone",
      "school",
      "address",
      "created on",
      "policy start date",
      "policy end date",
      "actions",
    ],
    data: csvLinkData,
  };

  const onNavigate = () => {
    history.push("/dashboard");
    window.location.reload();
  };

  const reset = () => {
    setFromDate("");
    setToDate("");
    setFilter("");
    setSearchText("");

    const _data = data?.policy_data?.filter(filterData);
    const startIndex = (activePage - 1) * PAGE_SIZE;
    const endIndex = activePage * PAGE_SIZE;
    const paginatedData = _data?.slice(startIndex, endIndex);

    setFilteredData(_data);
    setPaginatedData(paginatedData);
  };

  const handleViewOrEdit = (value: CustomerType, quotation_number: string) => {
    setSelectedRow(value);
    localStorage.setItem("selected-data", JSON.stringify(value));
    localStorage.setItem("quote_number", quotation_number);
    history.push("/dashboard");
    window.location.reload();
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem("personal-details");
      localStorage.removeItem("quote_number");
      localStorage.removeItem("loggedIn");
      history?.push("/");
      window.location.reload();
    } catch (error) { }
  };

  return (
    <div className="overflow-scroll scrolls bg-gray-100 h-screen px-2 md:px-4 lg:px-6 ">
      <div className="sticky top-0 z-50 mb-5">
        <div className="bg-gray-100 py-5 ">
          <div className="py-5 sticky top-0 z-50 w-full flex flex-row items-center justify-between">
            <div className="flex items-center justify-center">
              <img alt="logo" src={Logo} className="w-36" />
            </div>

            <div className="hidden md:block lg:block">
              <h1 className="text-brand-blue font-semibold uppercase text-lg lg:text-xl text-center">
                PERSONAL <span className="">ACCIDENT INSURANCE</span>
              </h1>
              <div className="flex flex-col items-center justify-between md:flex-row mt-2">
                <div className="w-full flex gap-2">
                  <button className="px-2 py-2.5 rounded-md text-white bg-brand-blue">
                    <span className="text-lg lg:text-2xl">
                      {data && data?.policy_data?.length}
                    </span>
                    <p className="font-semibold text-[16px] flex-col">
                      Total Policies

                    </p>
                  </button>
                  <button className="px-2 py-2.5 rounded-md text-white bg-brand-red">
                    <span className="text-lg lg:text-2xl">
                      GHS{" "} {data &&
                        (data?.policy_data?.length * 50).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                    </span>
                    <p className="font-bold text-[16px]">
                      Total Premium

                    </p>
                  </button>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-x-5">
              <div className="lg:hidden md:hidden flex-col items-center justify-between md:flex-row">
                <div className="w-full flex gap-2">
                  <button className="px-2 py-2.5 rounded-md text-white bg-brand-blue">
                    <p className="font-semibold text-[12px]">
                      Total Policies:{" "}
                      <span className="text-lg lg:text-xl">
                        {data && data?.policy_data?.length}
                      </span>
                    </p>
                  </button>
                  <button className="px-2 py-2.5 rounded-md text-white bg-brand-red">
                    <p className="font-bold text-[12px]">
                      Total Premium: GHS{" "}
                      <span className="text-lg lg:text-xl">
                        {data &&
                          (data?.policy_data?.length * 50).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                      </span>
                    </p>
                  </button>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="flex flex-row items-center gap-4">
                  <Tooltip content="Add new business">
                    <Button
                      onClick={onNavigate}
                      className=" bg-brand-red capitalize flex items-center justify-center gap-2"
                      size="lg"
                    >
                      <FiPlus size={16} />{" "}
                      <span className="font-base text-xs md:text-sm lg:text-sm font-bold">
                        New business
                      </span>
                    </Button>
                  </Tooltip>
                  <Tooltip content="Log out">
                    <IconButton
                      onClick={handleLogout}
                      className=" bg-brand-red capitalize flex items-center justify-center gap-2"
                      size="lg"
                    >
                      <BiLogOut size={18} />{" "}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="block lg:hidden">
            <div className="flex flex-row items-center gap-4">
              <Tooltip content="Add new business">
                <Button
                  onClick={onNavigate}
                  className=" bg-brand-red capitalize flex items-center justify-center gap-2"
                  size="lg"
                >
                  <FiPlus size={16} />{" "}
                  <span className="font-base text-xs md:text-sm lg:text-sm font-bold">
                    New business
                  </span>
                </Button>
              </Tooltip>
              <Tooltip content="Log out">
                <IconButton
                  onClick={handleLogout}
                  className=" bg-brand-red capitalize flex items-center justify-center gap-2"
                  size="lg"
                >
                  <BiLogOut size={18} />{" "}
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex flex-col md:flex-row shadow-sm lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-xl">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3  w-full">
            <div className="flex flex-row items-center w-full mr-3">
              <Typography className="w-28">Filter by</Typography>
              <select
                className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={filters}
                onChange={handleFilterChange}
              >
                <option selected>Select School</option>
                {schools?.map((item) => (
                  <option
                    className="capitalize"
                    value={item}
                    key={item}
                    label={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full">
              <div className="relative flex w-full">
                <Button
                  ripple={false}
                  variant="text"
                  color="blue-gray"
                  className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
                >
                  {" "}
                  From
                </Button>
                <Input
                  type="date"
                  className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  size="md"
                  containerProps={{
                    className: "min-w-0",
                  }}
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="relative flex w-full ">
                <Button
                  ripple={false}
                  variant="text"
                  color="blue-gray"
                  className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
                >
                  {" "}
                  To
                </Button>
                <Input
                  type="date"
                  className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  size="md"
                  containerProps={{
                    className: "min-w-0",
                  }}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full flex flex-row items-end justify-end gap-2">
              <Button
                onClick={generateReport}
                className="bg-brand-blue font-normal w-1/2 flex items-center justify-center text-sm capitalize"
                size="md"
              >
                {generating ? (
                  <Spinner className="w-4 h-4" />
                ) : (
                  "Generate Report"
                )}
              </Button>

              <Tooltip content="Reset">
                <Button
                  onClick={reset}
                  size="md"
                  className="px-4 bg-brand-blue"
                >
                  <BiReset size={20} />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="mt-3 bg-white shadow-sm p-3 rounded-xl">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-3 flex items-center justify-between gap-8">
              <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                <div className="w-full md:w-72">
                  <Input
                    value={searchText}
                    onChange={handleSearch}
                    label="Search"
                    icon={<BiSearch className="h-5 w-5" />}
                  />
                </div>
              </div>

              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <CSVLink {...csvLink}>
                  <Tooltip content={`Export csv`}>
                    <Button
                      variant="filled"
                      size="sm"
                      className="bg-brand-blue text-sm capitalize font-normal"
                    >
                      Export csv
                    </Button>
                  </Tooltip>
                </CSVLink>
              </div>
            </div>
          </CardHeader>
          <CardBody className="overflow-scroll scrolls px-0">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {[
                    "client name",
                    // "email",
                    "phone",
                    "school",
                    "address",
                    "created on",
                    "policy start date",
                    "policy end date",
                  ].map((head) => (
                    <th
                      key={head}
                      className="capitalize text-left border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData?.map((item: ReportType, index: number) => {
                  const isLast = index === filteredData?.length - 1;
                  const classes = isLast
                    ? "py-4 px-3  text-left"
                    : "py-4 px-3 border-b border-blue-gray-50 text-left";

                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        let data = {
                          policy_start_date: item?.start_date,
                          ...item?.pa_customer,
                        };
                        handleViewOrEdit(
                          data as CustomerType,
                          item?.quotation_number
                        );
                      }}
                      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                    >
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className=" font-normal text-xs capitalize"
                        >
                          {`${item?.pa_customer?.first_name} ${item?.pa_customer?.last_name}`}
                        </Typography>
                      </td>
                      {/* <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs"
                        >
                          {item?.pa_customer?.email
                            ? item?.pa_customer?.email
                            : "---"}
                        </Typography>
                      </td> */}
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs"
                        >
                          {item?.pa_customer?.phone_number
                            ? item?.pa_customer?.phone_number
                            : "---"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          {item?.pa_customer?.school
                            ? item?.pa_customer?.school
                            : "---"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          {item?.pa_customer?.house_street}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          {moment(item?.pa_customer?.created_at).format("LLL")}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs"
                        >
                          {moment(item?.start_date).format("LLL")}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs"
                        >
                          {moment(item?.end_date).format("LLL")}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
        </div>
      </div>

      {loading && (
        <div className="mt-10 flex items-center justify-center">
          <Spinner className="w-1o h-10" />
        </div>
      )}

      {!paginatedData && !loading && (
        <div className="mt-10 flex items-center justify-center">
          <Typography variant="h6">No data found</Typography>
        </div>
      )}

      {paginatedData && (
        <div className="my-10 flex items-center justify-center">
          <div className="flex items-center gap-8">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prevPage}
              disabled={activePage === 1}
            >
              <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="gray" className="font-normal">
              Page <strong className="text-gray-900">{activePage}</strong> of{" "}
              <strong className="text-gray-900">
                {Math.ceil(
                  (filteredData?.filter(filterData).length || 0) / PAGE_SIZE
                )}
              </strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={nextPage}
              disabled={
                activePage ===
                Math.ceil(
                  (filteredData?.filter(filterData).length || 0) / PAGE_SIZE
                )
              }
            >
              <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
        </div>
      )}

      {/* <div className="sticky bottom-0 z-50 mb-5">
        <div className="bg-gray-100 py-5 ">
          <div className="py-5 sticky bottom-0 z-50 w-full flex flex-row items-center justify-between"></div>
        </div>
      </div> */}
    </div>
  );
};

export default memo(ReportView);
