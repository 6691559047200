import { useQuery, gql } from "@apollo/client";

export const GET_ALL_POLICY = gql`
query getAllPolicies {
  policy_data: pa_requests(where: {is_paid: {_eq: true}}, order_by: {created_at: desc}) {
    currency
    duration
    end_date
    id
    is_paid
    medical_expense_limit
    weekly_benefit_per_week
    other_data
    premium_amount
    quotation_number
    start_date
    capital_sum_assured
    type_of_cover
    pa_customer {
      dob
      email
      first_name
      house_street
      gps_address
      last_name
      location
      school
      phone_number
      beneficiaries {
        dob
        email
        first_name
        last_name
        location
        id
        percentage_share
        relationship
      }
      created_at
      id
    }
  }
}

`;

export const GET_ALL_CLIENT = gql`
  query MyQuery {
    pa_customers {
      created_at
      dob
      email
      first_name
      gps_address
      house_street
      id
      last_name
      location
      other_data
      school
      phone_number
    }
}
`;
// beneficiaries
//personal-details