import {
  Button,
  CardBody,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState, useMemo } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import moment from "moment";
import { CustomModal } from "../../../components";
import logo from "../../../assets/logo.png";
import qrCode from "../../../assets/glico.png";
import { toast } from "react-toastify";
import { BASEURL } from "../../../util";
import { QuoteType } from "../../../types/quote";
import ReactDOMServer from "react-dom/server";
import { IoMdPrint } from "react-icons/io";

const TABLE_HEAD = ["Reciept name", "date created", "", ""];

const RecieptView = () => {
  const [loading, setLoading] = useState(true);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [openQuotationModal, setQuotationModal] = useState(false);
  const [quoteDetail, setQuoteDetail] = useState<QuoteType>();

  const handlePrintQuotation = () => {
    const printContent = (
      <div>
        <style>
          {`
            body {
              font-family: 'Arial', sans-serif;
              margin: 20px;
              color: #333;
              background-color: #f2f5f8;
            }
  
            h2 {
              color: #1a202c;
              font-size: 1.8rem;
              text-decoration: underline;
              margin-bottom: 20px;
            }
  
            p {
              margin-bottom: 10px;
              line-height: 1.5;
            }
  
            .logo {
              width: 30% !important;
            }
  
            .section {
              margin-top: 20px;
              padding: 20px;
              border: 1px solid #e2e8f0;
              background-color: #ffffff;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              margin-bottom: 20px;
            }
  
            .beneficiary {
              margin-top: 20px;
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
              gap: 20px;
            }
        
            .beneficiary-item {
              padding: 20px;
              border: 1px solid #e2e8f0;
              border-radius: 8px;
            }
  
            .total {
              margin-top: 20px;
              text-align: right;
            }
  
            .total > p {
              font-weight: 700;
            }
  
            .button-container {
              margin-top: 20px;
              text-align: center;
            }
  
            .button {
              background-color: #3490dc;
              color: #ffffff;
              padding: 12px 24px;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              font-size: 1rem;
            }
          `}
        </style>

        <div className="section">
          <div className="flex justify-between items-center">
            <img src={logo} className="logo" alt="Logo" />
            <h2>Quotation Details</h2>
          </div>

          <p>Quotation Number: {quoteDetail?.quotation_number}</p>
          <p>Type of Cover: {quoteDetail?.type_of_cover}</p>

          {/* Customer Details */}
          <div className="section">
            <h2>Customer Details</h2>
            <p>
              Name: {quoteDetail?.pa_customer?.first_name}{" "}
              {quoteDetail?.pa_customer?.last_name}
            </p>
            <p>Email: {quoteDetail?.pa_customer?.email}</p>
            <p>Phone Number: {quoteDetail?.pa_customer?.phone_number}</p>
          </div>

          {/* Beneficiaries */}
          <h2>Beneficiaries</h2>
          <div className="beneficiary">
            {quoteDetail?.pa_customer?.beneficiaries?.map(
              (beneficiary, index) => (
                <div key={index} className="beneficiary-item">
                  <p>
                    Name: {beneficiary.first_name} {beneficiary.last_name}
                  </p>
                  <p>Relationship: {beneficiary.relationship}</p>
                  <p>Percentage Share: {beneficiary.percentage_share}%</p>
                </div>
              )
            )}
          </div>

          {/* Policy Details */}
          <div className="section">
            <h2>Policy Details</h2>
            <p>
              Policy Start Date: {moment(quoteDetail?.start_date).format("LLL")}
            </p>
            <p>
              Policy End Date: {moment(quoteDetail?.end_date).format("LLL")}
            </p>
            <p>Number of Months: 12</p>
          </div>

          {/* Premium */}
          <div className="total">
            <p>Premium Payable: GHS {quoteDetail?.premium_amount}.00</p>
          </div>
        </div>
      </div>
    );

    const printWindow = window.open("", "_blank");

    if (printWindow) {
      const printHTML = ReactDOMServer.renderToStaticMarkup(printContent);

      printWindow.document.write(`
        <html>
          <head>
            <title>Quotation Details</title>
          </head>
          <body>
          ${printHTML}
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      toast.error("Failed to open the print window");
    }
  };

  // const printPolicy = () => {
  //   const printWindow = window.open("", "_blank");

  //   if (printWindow) {
  //     const printContent = (
  //       <div>
  //         <style>
  //           {`
  //             body {
  //               font-family: Arial, sans-serif;
  //               margin: 20px;
  //             }

  //             h2 {
  //               color: #333;
  //               text-decoration: underline;
  //             }

  //             p {
  //               margin-bottom: 10px;
  //             }

  //             .logo {
  //               max-width: 100px;
  //             }

  //             .qr-code {
  //               width: 72px;
  //               height: 72px;
  //             }
  //           `}
  //         </style>
  //         <h2>Policy information</h2>

  //         {/* Include the content from PolicyDetailComponent */}
  //         <PolicyDetailComponent {...(quoteDetail as QuoteType)} />

  //         {/* Logo */}
  //         <div className="flex items-center justify-center">
  //           <img src={logo} className="logo" alt="Logo" />
  //         </div>

  //         {/* QR Code */}
  //         <div className="flex items-center justify-center">
  //           <img src={qrCode} className="qr-code" alt="QR Code" />
  //         </div>
  //       </div>
  //     );

  //     const printHTML = ReactDOMServer.renderToStaticMarkup(printContent);

  //     printWindow.document.write(`
  //       <html>
  //         <head>
  //           <title>Policy information</title>
  //         </head>
  //         <body>
  //         ${printHTML}
  //         </body>
  //       </html>
  //     `);

  //     printWindow.document.close();
  //     printWindow.print();
  //   } else {
  //     toast.error("Failed to open the print window");
  //   }
  // };

  const printPolicy = () => {
    const printContent = (
      <div>
        <style>
          {`
            body {
              font-family: 'Arial', sans-serif;
              margin: 20px;
              color: #333;
              background-color: #f2f5f8;
            }
  
            h2 {
              color: #1a202c;
              font-size: 1.5rem;
              text-decoration: underline;
              margin-bottom: 20px;
            }
  
            p {
              margin-bottom: 8px;
              line-height: 1.3;
              font-size: 16px !important;
            }

            .test {
            
              font-size: 16px !important;
            }
  
            .logo {
              max-width: 80px;
            }
  
            .qr-code {
              width: 60px;
              height: 60px;
            }
  
            .section {
              margin-top: 20px;
              padding: 20px;
              border: 1px solid #e2e8f0;
              background-color: #ffffff;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              border-radius: 8px;
              margin-bottom: 20px;
            }
  
            .flex-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
            }
  
            .address {
              text-align: center;
              font-size: 0.7rem;
              color: #555;
            }
  
            .policy-details {
              margin-top: 20px;
            }
  
            .beneficiary-details {
              margin-top: 20px;
            }
  
            .beneficiary-item {
              margin-bottom: 20px;
            }
  
            .total {
              margin-top: 20px;
              text-align: right;
            }
  
            .total > p {
              font-weight: 700;
            }
  
            .notice {
              margin-top: 10px;
              text-align: center;
              font-size: 10px;
              color: #ff0000;
            }
          `}
        </style>

        <div className="section">
          <div className="flex-container">
            <img src={logo} className="logo" alt="Logo" />
            <h2>Policy Information</h2>
          </div>

          <div className="flex-container">
            <img src={qrCode} className="qr-code" alt="Logo" />
          </div>

          <div className="flex-container address">
            <div>
              <p>47, Kwame Nkrumah Avenue</p>
              <p>Adabraka, Accra</p>
            </div>

            {/* Add QR Code here */}
          </div>

          <div className="mt-4 policy-details">
            <h1 className="text-lg font-bold text-black capitalize text-center">
              PERSONAL ACCIDENT COVER
            </h1>

            <div className="my-3">
              <h1 className="text-sm test text-brand-red font-semibold capitalize">
                Policy Number
              </h1>
              <p className="text-sm text-brand-blue font-semibold capitalize">
                {quoteDetail?.quotation_number}
              </p>
            </div>
            <hr />
            <div className="my-3">
              <h1 className="text-sm test text-brand-red font-semibold capitalize">
                Policy Holder
              </h1>
              <p className="text-sm text-brand-blue font-semibold capitalize">
                {`${quoteDetail?.pa_customer?.first_name} ${quoteDetail?.pa_customer?.last_name}`}
              </p>
            </div>
            <hr />
            <div className="my-3">
              <h1 className="text-sm test text-brand-red font-semibold capitalize">
                School
              </h1>
              <p className="text-sm text-brand-blue font-semibold capitalize">
                {quoteDetail?.pa_customer?.school}
              </p>
            </div>
            <hr />
            <div className="my-3">
              <h1 className="text-sm test text-brand-red font-semibold">
                Summary of Benefits
              </h1>
              <div className="ml-4">
                <div className="my-3">
                  <h1 className="text-xs test text-brand-red capitalize">
                    Capital sum assured
                  </h1>
                  <p className="text-xs text-brand-blue capitalize">
                    {quoteDetail?.capital_sum_assured}
                  </p>
                </div>
                <hr />
                <div className="my-3">
                  <h1 className="text-xs test text-brand-red">
                    Temporary Disablement - For maximum 52 weeks
                  </h1>
                  <p className="text-xs text-brand-blue capitalize">
                    {quoteDetail?.weekly_benefit_per_week}
                  </p>
                </div>
                <hr />
                <div className="my-3">
                  <h1 className="text-xs test text-brand-red capitalize">
                    Medical Expense Limit
                  </h1>
                  <p className="text-xs text-brand-blue capitalize">
                    {quoteDetail?.medical_expense_limit}
                  </p>
                </div>
                <hr />
              </div>
            </div>
            <div className="my-3 beneficiary-details">
              <h1 className="text-sm text-brand-red font-semibold capitalize">
                Beneficiaries
              </h1>
              <div className="flex-container">
                {quoteDetail?.pa_customer?.beneficiaries?.map((item) => (
                  <div key={item?.email} className="beneficiary-item">
                    <div className="my-3">
                      <h1 className="text-xs test text-brand-red capitalize">
                        {`${item?.first_name} ${item?.last_name}`}
                      </h1>
                      <p className="text-xs text-brand-blue capitalize">
                        {item?.percentage_share}%
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="my-3">
              <h1 className="text-sm test text-brand-red font-semibold capitalize">
                Policy Start date
              </h1>
              <p className="text-sm text-brand-blue font-semibold capitalize">
                {moment(quoteDetail?.start_date).format("LLL")}
              </p>
            </div>
            <hr />
            <div className="my-3">
              <h1 className="text-sm test text-brand-red font-semibold capitalize">
                Policy End date
              </h1>
              <p className="text-sm text-brand-blue font-semibold capitalize">
                {moment(quoteDetail?.end_date).format("LLL")}
              </p>
            </div>
            <div className="my-3">
              <h1 className="text-sm test text-brand-red font-semibold capitalize">
                Premium Paid
              </h1>
              <p className="text-sm text-brand-blue font-semibold capitalize">
                GHS {quoteDetail?.premium_amount}.00
              </p>
            </div>
            <hr />
            <div className="mt-2 notice">
              <span>
                Important Notice: Please refer to the standard policy for full
                terms and conditions
              </span>
            </div>
          </div>
        </div>
      </div>
    );

    const printWindow = window.open("", "_blank");

    if (printWindow) {
      const printHTML = ReactDOMServer.renderToStaticMarkup(printContent);

      printWindow.document.write(`
        <html>
          <head>
            <title>Policy Information</title>
          </head>
          <body>
          ${printHTML}
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      toast.error("Failed to open the print window");
    }
  };

  const ReceiptData = [
    {
      reciept_name: "Quotation Information",
      createdAt: moment().format("LLL"),
      onClick: () => {
        setQuotationModal(true);
      },
      onPrint: () => handlePrintQuotation(),
    },
    {
      reciept_name: "Policy reciept",
      createdAt: moment().format("LLL"),
      onClick: () => {
        setOpenPolicyModal(true);
      },
      onPrint: () => printPolicy(),
    },
  ];

  const fetchData = async () => {
    try {
      const res = await fetch(`${BASEURL}/get-pa-quote-details`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quote_number: localStorage.getItem("quote_number"),
        }),
      });

      const req = await res.json();

      console.log("req", req);

      if (res.ok) {
        toast.success(req.message);
        setQuoteDetail(req.data);
      } else {
        toast.info(req?.message);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log("error", error);
      console.log("error", error.message);
      toast.error(error?.message ?? "Failed to fetch data");
    }
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");

    if (printWindow) {
      const printContent = (
        <div>
          <style>
            {`
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }

              h2 {
                color: #333;
                text-decoration: underline;
              }

              p {
                margin-bottom: 10px;
              }

              .customer-details {
                margin-top: 20px;
                border: 1px solid #ddd;
                padding: 10px;
              }

              .beneficiary-details {
                margin-top: 20px;
              }

              .beneficiary-item {
                margin-bottom: 10px;
              }
            `}
          </style>
          <h2>Quotation Details</h2>
          <p>Quotation Number: {quoteDetail?.quotation_number}</p>
          <p>Type of Cover: {quoteDetail?.type_of_cover}</p>
          {/* Add more details as needed */}

          {/* Customer Details */}
          <div className="customer-details">
            <h2>Customer Details</h2>
            <p>
              Name: {quoteDetail?.pa_customer?.first_name}{" "}
              {quoteDetail?.pa_customer?.last_name}
            </p>
            <p>Email: {quoteDetail?.pa_customer?.email}</p>
            <p>Phone Number: {quoteDetail?.pa_customer?.phone_number}</p>
            {/* Add more customer details as needed */}
          </div>

          {/* Beneficiary Details */}
          <div className="beneficiary-details">
            <h2>Beneficiaries</h2>
            {quoteDetail?.pa_customer?.beneficiaries?.map(
              (beneficiary, index) => (
                <div key={index} className="beneficiary-item">
                  <p>
                    Name: {beneficiary.first_name} {beneficiary.last_name}
                  </p>
                  <p>Relationship: {beneficiary.relationship}</p>
                  <p>Percentage Share: {beneficiary.percentage_share}%</p>
                  {/* Add more beneficiary details as needed */}
                </div>
              )
            )}
          </div>
        </div>
      );

      const printHTML = ReactDOMServer.renderToStaticMarkup(printContent);

      printWindow.document.write(`
        <html>
          <head>
            <title>Quotation Details</title>
          </head>
          <body>
          ${printHTML}
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("Failed to open the print window");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <CustomModal
        open={openQuotationModal}
        onClose={() => setQuotationModal(false)}
        children={<QuotationDetailComponent {...(quoteDetail as QuoteType)} />}
        size={"md"}
      />

      <CustomModal
        open={openPolicyModal}
        onClose={() => setOpenPolicyModal(false)}
        children={<PolicyDetailComponent {...(quoteDetail as QuoteType)} />}
        size={"md"}
      />
      <CustomHeader title="Receipts" />
      <div className="w-full">
        <div className="flex flex-end justify-end gap-3 w-full">
          <Button
            onClick={handlePrint}
            disabled={!quoteDetail}
            className="text-sm font-normal capitalize bg-brand-red"
          >
            Print all
          </Button>
        </div>
      </div>

      <div className="mt-10">
        <CardBody className="overflow-x-scroll scrolls px-0  rounded-3xl bg-white">
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {quoteDetail
                ? ReceiptData?.map((item: any, index: number) => {
                  const isLast = index === ReceiptData?.length - 1;
                  const classes = isLast
                    ? "px-2 py-1.5  text-center"
                    : "px-2 py-1.5 border-b border-blue-gray-50 text-center";

                  return (
                    <tr
                      key={item?.reciept_name + index.toString()}
                      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                    >
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {item?.reciept_name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          {item?.createdAt}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Tooltip content="View">
                          <Button
                            size="sm"
                            onClick={item.onClick}
                            variant="filled"
                            className="bg-brand-blue font-normal text-[9px] capitalize px-5"
                          >
                            View more
                          </Button>
                        </Tooltip>
                      </td>
                      <td className={classes}>
                        <Tooltip content="Print">
                          <Button
                            size="md"
                            onClick={item.onPrint}
                            variant="filled"
                            className=" font-normal text-[15px] capitalize px-5"
                          >
                            <IoMdPrint />
                          </Button>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </table>

          {!quoteDetail && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography variant="h6">No data found</Typography>
            </div>
          )}
        </CardBody>
        {loading && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )}
      </div>
    </>
  );
};

const PolicyDetailComponent = (props: QuoteType | undefined) => {
  return (
    <div>
      <div className="flex items-center justify-center">
        <img src={logo} className="w-28 qr-code" />
      </div>

      <Typography className="text-base font-bold text-center uppercase mt-4 text-brand-blue">
        <span className="text-brand-blue">Glico general</span> insurance
        limited
      </Typography>

      <div className="flex flex-row mt-4 justify-between">
        <div></div>

        <div className="text-center text-sm text-brand-blue">
          <p>47 Kwame Nkrumah Avenue, Adabraka, Accra</p>
          {/* <p>Adabraka, Accra</p> */}
        </div>
        <div className="flex items-center justify-center">
          <img src={qrCode} className="w-12 h-12 object-cover logo" />
        </div>
      </div>

      <div className="mt-4">
        <h1 className="text-lg font-bold text-black capitalize text-center">
          PERSONAL ACCIDENT COVER
        </h1>
        <div className="mt-4">
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy Number
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {props?.quotation_number}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              PolicyHolder
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {`${props?.pa_customer?.first_name} ${props?.pa_customer?.last_name}`}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              School
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {props?.pa_customer?.school}
            </h1>
          </div>
          <hr />
          <div className="my-3">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-sm  text-brand-red font-semibold  ">
                Summary of Benefits
              </h1>
            </div>
            <div className="ml-4">
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red   capitalize ">
                  Capital sum assured
                </h1>
                <h1 className="text-xs text-brand-blue capitalize ">
                  GHS {props?.capital_sum_assured}
                </h1>
              </div>
              <hr />
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red   ">
                  Tempory Disablement  Weekly Benefit - For maximum 52 weeks
                </h1>
                <h1 className="text-xs text-brand-blue   capitalize ">
                  GHS {props?.weekly_benefit_per_week}
                </h1>
              </div>
              <hr />
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red capitalize ">
                  Medical Expense Limit
                </h1>
                <h1 className="text-xs text-brand-blue capitalize ">
                  GHS {props?.medical_expense_limit}
                </h1>
              </div>
              <hr />
            </div>
          </div>
          <div className="my-3">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Beneficaries
              </h1>
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Percent share
              </h1>
            </div>
            {props?.pa_customer?.beneficiaries?.map((item) => (
              <div key={item?.email}>
                <hr />
                <div className="ml-4">
                  <div className="my-3 flex flex-row items-center justify-between">
                    <h1 className="text-xs  text-brand-red   capitalize ">
                      {`${item?.first_name} ${item?.last_name}`}
                    </h1>
                    <h1 className="text-xs text-brand-blue capitalize ">
                      {item?.percentage_share}%
                    </h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy start date{" "}
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {moment(props?.start_date).format("LL")}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy End date{" "}
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {moment(props?.end_date).format("LL")}
            </h1>
          </div>
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Premium Paid
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              GHS {props?.premium_amount}.00
            </h1>
          </div>
          <hr />

          {/* <div className="mt-7">
            <Button className="w-full bg-red-100 text-brand-red py-3 text-sm">
              premium paid: GH {props?.premium_amount}
            </Button>
          </div> */}

          <div className="mt-2 flex items-center justify-center">
            <span className="text-[10px] text-center font-medium text-brand-red">
              Important Notice: Please refer to the standard policy for full
              terms and conditions
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuotationDetailComponent = (props: QuoteType | undefined) => {
  return (
    <>
      <div className="flex items-center justify-center">
        <img src={logo} className="w-28" />
      </div>

      <div className="mt-4">
        <h1 className="text-lg font-bold text-black capitalize text-center">
          Quotation information
        </h1>
        <div className="mt-4">
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold  ">
              Type of Cover
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {props?.type_of_cover}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Quotation number
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {props?.quotation_number}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Client name
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {`${props?.pa_customer?.first_name} ${props?.pa_customer?.last_name}`}
            </h1>
          </div>
          <hr />

          <div className="my-3">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-sm  text-brand-red font-semibold ">
                Summary of Benefits
              </h1>
            </div>
            <div className="ml-4">
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red   capitalize ">
                  Capital sum assured
                </h1>
                <h1 className="text-xs text-brand-blue capitalize ">
                  GHS {props?.capital_sum_assured}
                </h1>
              </div>
              <hr />
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red   ">
                  Tempory Disablement  Weekly Benefit - For maximum 52 weeks
                </h1>
                <h1 className="text-xs text-brand-blue   capitalize ">
                  GHS {props?.weekly_benefit_per_week}
                </h1>
              </div>
              <hr />
              <div className="my-3 flex flex-row items-center justify-between">
                <h1 className="text-xs  text-brand-red capitalize ">
                  Medical Expense Limit
                </h1>
                <h1 className="text-xs text-brand-blue capitalize ">
                  GHS {props?.medical_expense_limit}
                </h1>
              </div>
            </div>
          </div>
          <hr />
          <div className="my-3">
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Beneficaries
              </h1>
              <h1 className="text-sm  text-brand-red font-semibold capitalize ">
                Percent share
              </h1>
            </div>
            {props?.pa_customer?.beneficiaries?.map((item) => (
              <div key={item?.email}>
                <hr />
                <div className="ml-4">
                  <div className="my-3 flex flex-row items-center justify-between">
                    <h1 className="text-xs  text-brand-red   capitalize ">
                      {`${item?.first_name} ${item?.last_name}`}
                    </h1>
                    <h1 className="text-xs text-brand-blue capitalize ">
                      {item?.percentage_share}%
                    </h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy start date{" "}
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {moment(props?.start_date).format("LL")}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Policy End date{" "}
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              {moment(props?.end_date).format("LL")}
            </h1>
          </div>
          <hr />
          <div className="my-3 flex flex-row items-center justify-between">
            <h1 className="text-sm  text-brand-red font-semibold capitalize ">
              Number of months
            </h1>
            <h1 className="text-sm text-brand-blue font-semibold capitalize ">
              12
            </h1>
          </div>

          <div className="mt-7">
            <Button className="w-full bg-red-100 text-brand-red py-3 text-sm">
              Premium payable: GHS {props?.premium_amount}.00
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecieptView;
