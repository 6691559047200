import { type } from "os";
import React from "react";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormWatch,
} from "react-hook-form";
import { AddBeneficiaryType } from "../../types/admin";
import {
  Button,
  DialogFooter,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";

type FormProps = {};

export const AddBeneficiaryModal = ({
  handleSubmit,
  register,
  errors,
  handleOpen,
  onSubmit,
  isSubmitting,
  watch,
}: {
  handleSubmit: UseFormHandleSubmit<AddBeneficiaryType, undefined>;
  register: UseFormRegister<AddBeneficiaryType>;
  errors: FieldErrors<AddBeneficiaryType>;
  handleOpen: () => void;
  isSubmitting: boolean;
  onSubmit: SubmitHandler<AddBeneficiaryType>;
  watch: UseFormWatch<AddBeneficiaryType>;
}) => {
  const watchRelationship = watch("relationship");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-semibold text-sm"
          >
            First name
          </Typography>
          <Input
            error={!!errors.first_name}
            size="md"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("first_name", { required: true })}
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-semibold text-sm"
          >
            Surname
          </Typography>
          <Input
            error={!!errors.last_name}
            size="md"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("last_name", { required: true })}
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-semibold text-sm"
          >
            Email
          </Typography>
          <Input
            error={!!errors.email}
            size="md"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("email", { required: false })}
          />
        </div>
      </div>

      <div className="mb-6 flex flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-semibold text-sm capitalize"
          >
            Phone number
          </Typography>
          <Input
            error={!!errors.phone_number}
            size="md"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("phone_number", { required: true })}
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-semibold text-sm"
          >
            Residential address/Street name
          </Typography>
          <Input
            error={!!errors.house_street}
            size="lg"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("house_street", { required: true })}
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-semibold text-sm capitalize"
          >
            location
          </Typography>
          <Input
            error={!!errors.location}
            size="md"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("location", { required: true })}
          />
        </div>

      </div>
      <div className="mb-6 flex  flex-col md:flex-row lg:flex-row gap-6">
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-semibold text-sm"
          >
           Date of Birth
          </Typography>
          <Input
            error={!!errors.dob}
            size="md"
            type="date"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("dob", { required: false })}
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-semibold text-sm"
          >
            percentage
          </Typography>
          <Input
            error={!!errors.percentage_share}
            size="md"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            {...register("percentage_share", { required: true })}
          />
        </div>
        <div className="w-full">
          <Typography
            variant="h6"
            color="blue-gray"
            className="mb-2 font-semibold text-sm"
          >
            relationship
          </Typography>
          <select
            id="countries"
            className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register("relationship", { required: true })}
          >

            <option label="Select relationship" value="">
            Select relationship
            </option>

            <option label="parent" value={"parent"}>
              parent
            </option>

            <option label="nephew" value={"nephew"}>
              nephew
            </option>
            <option label="niece" value={"niece"}>
              niece
            </option>
            <option label="child" value={"child"}>
              child
            </option>
            <option label="spouse" value={"spouse"}>
              spouse
            </option>
            <option label="sibling" value={"sibling"}>
              sibling
            </option>
            <option label="other" value={"other"}>
              other
            </option>
          </select>

          <p> {errors.relationship ? "Required" : ""}</p>
        </div>
      </div>
      {watchRelationship === "other" ? (
        <div className="mb-6 w-full md:w-1/4">
          <div className="w-full">
            <Typography
              variant="h6"
              color="blue-gray"
              className="mb-2 font-semibold text-sm"
            >
              Type other relationship here
            </Typography>
            <Input
              error={!!errors.other_relationship}
              size="md"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              {...register("other_relationship", { required: true })}
            />
          </div>
        </div>
      ) : null}

      <DialogFooter>
        <Button
          variant="filled"
          onClick={handleOpen}
          className="mr-1 bg-brand-red text-white"
        >
          <span>Cancel</span>
        </Button>
        <Button
          type="submit"
          className="flex items-center justify-center text-white bg-brand-blue"
          variant="filled"
        >
          {isSubmitting ? <Spinner className="h-4 w-4" /> : <span>Save</span>}
        </Button>
      </DialogFooter>
    </form>
  );
};
