export const BASEURL = "https://pos.wigal.com.gh/api/v1";

export function calculateDaysLeft(startDate: string, endDate: string) {
  // Convert the dates to milliseconds
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  // Calculate the difference in milliseconds
  const difference = end - start;

  // Convert the difference from milliseconds to days
  const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

  return daysDifference;
}
