import { Button, Input, Spinner, Typography } from "@material-tailwind/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/logo.png";

type LoginType = {
  email: string;
  password: string;
};

export default function LoginEmail() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<LoginType>();

  const onSubmit: SubmitHandler<LoginType> = async (data) => {
    const value = "true";
    if (data.email !== "staff@glico.com") {
      return toast.error("Email does not exist");
    }
    if (data.password !== "glicopass") {
      return toast.error("password incorrect");
    }
    localStorage.setItem("loggedIn", value);
    history.push("/report");
    window.location.reload();
  };

  return (
    <>
      <div className="h-screen bg-white mx-auto max-w-md flex flex-col items-center justify-center">
        <div className="w-full">
          <div className="flex items-center justify-center">
            <img alt="logo" src={Logo} className="w-36" />
          </div>
          <Typography className="text-base font-bold text-center uppercase mt-4 text-brand-red">
            <span className="text-brand-blue">Glico general</span> insurance
            limited
          </Typography>
          <div className="text-center text-sm mt-2 text-brand-blue">
            <p>47 Kwame Nkrumah Avenue, Adabraka, Accra</p>
           
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white p-4 shadow-xl rounded-lg w-full mt-5"
          >

            <div className="mt-4"></div>
            <div className="mt-7">
              <div className="my-4">
                <label className="text-xs text-gray-600 mb-2">Email</label>
                <Input
                  error={!!errors?.email}
                  type="email"
                  className=""
                  containerProps={{
                    className: "min-w-0",
                  }}
                  {...register("email", { required: true })}
                />
              </div>

              <div className="my-4">
                <div className="flex flex-row items-center justify-between mb-2">
                  <label className="text-xs text-gray-600">Password</label>
                  <Button className="shadow-none capitalize bg-white flex items-end justify-end text-brand-primary text-[10px] font-bold p-0 hover:bg-white">
                    Forgot password?
                  </Button>
                </div>

                <Input
                  error={!!errors?.password}
                  type="password"
                  className=""
                  containerProps={{
                    className: "min-w-0",
                  }}
                  {...register("password", { required: true })}
                />
              </div>
            </div>
            <div className="my-6">
              <Button
                type="submit"
                className="w-full bg-brand-primary flex items-center justify-center"
              >
                {isSubmitting ? <Spinner className="w-4 h-4" /> : "Login"}
              </Button>
            </div>
            <div className="mt-10">
              {/* <div className="flex flex-row items-center gap-3 text-center">
              <div className="w-full h-[0.4px] bg-brand-Secondary rounded-lg" />
              <div className="w-full">
                <Typography className="text-sm text-brand-black font-normal">
                  Or sign in with
                </Typography>
              </div>
              <div className="w-full h-[0.4px] bg-brand-Secondary rounded-lg" />
            </div> */}
              {/* <div className="mb-3">
              {socialIcons?.map((icon) => (
                <Tooltip conten={icon?.label} key={icon?.label}>
                  <IconButton>{icon?.icon}</IconButton>
                </Tooltip>
              ))}
            </div> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
