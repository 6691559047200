import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
  RouteProps,
} from "react-router-dom";
// import { LoginEmail, Signup } from "./pages";
import { DashboardContent } from "./components";
import { ComponentType, useState } from "react";
import { ReportView } from "./pages";
import CustomReceiptView from "./pages/receipt";
import LoginEmail from "./pages/auth";

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<any>;
}

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteProps) {
  let storageToken = localStorage.getItem("token");
  const loggedIn = localStorage.getItem("loggedIn");

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}
function App() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/dashboard" component={DashboardContent} />
        <Route path="/receipts" component={CustomReceiptView} />
        <Route path="/report" component={ReportView} />
        <Route path="/" component={LoginEmail} />
      </Switch>
    </Router>
  );
}

export default App;
