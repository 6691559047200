import {
  Avatar,
  Chip,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { FiSettings } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaBarsStaggered } from "react-icons/fa6";
import useDrawer from "../../hooks/use-drawer";
import { MobileSidebar } from "../Sidebar/MobileSidebar";
import NotificationDrawer from "../modal/notification-drawer";
import { NotificationType } from "../../types/notification";
import useSWR from "swr";
import { BASEURL } from "../../util";
import { useHistory } from "react-router-dom";

const HeaderIcon = () => {
  const { open, toggleDrawer, setOpen } = useDrawer();
  return (
    <>
      <MobileSidebar open={open} closeDrawer={() => setOpen(false)} />

      <div className="flex flex-row items-center justify-center gap-x-3">
        <div className="block md:hidden lg:hidden">
          <IconButton onClick={toggleDrawer} color="red" size="sm">
            {open ? (
              <AiOutlineCloseCircle size={20} />
            ) : (
              <FaBarsStaggered size={20} />
            )}
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default HeaderIcon;
