import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState, useMemo } from "react";
import { CustomModal } from "../../../components";
import useSWR from "swr";
import { BASEURL } from "../../../util";

import { ToastContainer, toast } from "react-toastify";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { AddBeneficiaryType } from "../../../types/admin";
import { AddBeneficiaryModal } from "../../../components/forms/add-beneficiary";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineDelete,
} from "react-icons/ai";
import { ReportType } from "../../../types/report";

const TABLE_HEAD = [
  "first name",
  "Surname",
  "Residential Address/Street Name",
  "email",
  "location",
  "total percentage added",
  "",
];

const BeneficiaryView = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [beneficiaries, setBeneficiares] = useState<AddBeneficiaryType[]>([]);
  const [percentage, setPercentage] = useState(0);

  let PAGE_SIZE = 10;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<AddBeneficiaryType>();

  const watchPercentage = watch("percentage_share");

  const handleOpen = () => setOpen(!open);

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (beneficiaries?.filter(filterRentals).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const filterRentals = (data: AddBeneficiaryType) => {
    const isTextMatch =
      data?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.first_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.house_street?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.last_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.location?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.percentage_share
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      data?.phone_number?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      data?.relationship?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const paginatedData = beneficiaries
    ?.filter(filterRentals)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const onSubmit: SubmitHandler<AddBeneficiaryType> = async (data) => {
    try {
      if (percentage > 100) {
        return toast.error("Percentage share cannot exceed 100");
      }

      if (data.relationship === "other") {
        data.relationship = data.other_relationship
          ? data.other_relationship
          : data?.relationship;
      }

      const newBeneficiary = {
        ...data,
      };

      if (percentage + parseFloat(data.percentage_share) <= 100) {
        setBeneficiares([...beneficiaries, newBeneficiary]);
        reset();
        toast.success("Beneficiary added");
      } else {
        return toast.error("Percentage share cannot exceed 100");
      }
    } catch (error) {
      // Handle error
    }
  };

  const onApplicationSubmit = async () => {
    if (percentage !== 100) {
      return toast.error("Total Percentage share must be 100");
    }

    try {
      setLoading(true);
      let storageData = localStorage.getItem("personal-details");
      let personalDetails = storageData ? JSON.parse(storageData) : null;

      let formattedData = {
        start_date: personalDetails?.start_date,
        duration: personalDetails?.duration,
        premium: personalDetails?.premium,
        capital_sum_assured: personalDetails?.capital_sum_assured,
        weekly_benefit_per_week: personalDetails?.weekly_benefit_per_week,
        medical_expense_limit: personalDetails?.medical_expense_limit,
        currency: personalDetails?.currency,
        end_date: personalDetails?.end_date,
        customer_details_metadata: {
          first_name: personalDetails?.customer_details_metadata?.first_name,
          last_name: personalDetails?.customer_details_metadata?.last_name,
          phone_number:
            personalDetails?.customer_details_metadata?.phone_number,
          email: personalDetails?.customer_details_metadata?.email,
          location: personalDetails?.customer_details_metadata?.location,
          dob: personalDetails?.customer_details_metadata?.dob,
          school: personalDetails?.customer_details_metadata?.school,
          house_street:
            personalDetails?.customer_details_metadata?.house_street,
          gps_address: personalDetails?.customer_details_metadata?.gps_address,
          beneficiaries: beneficiaries,
        },
      };

      const res = await fetch(`${BASEURL}/submit-personal-accident-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedData),
      });

      const req = await res.json();

      if (res.ok) {
        localStorage.setItem("quote_number", req?.data.quote_number);
        localStorage.removeItem("personal-details");
        setBeneficiares([]);
        toast.success(req?.message);
      } else {
        toast.warning("Something went wrong. Try again");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      return toast.error(error?.message ?? "Failed to submit data");
    }
  };

  const handleRemoveBeneficiary = (index: number) => {
    const updatedBeneficiaries = [...beneficiaries];
    updatedBeneficiaries.splice(index, 1);
    setBeneficiares(updatedBeneficiaries);
  };

  React.useEffect(() => {
    // Calculate the total percentage from the beneficiaries array
    const totalPercentage = beneficiaries.reduce(
      (accumulator, currentBeneficiary) => {
        // Use the correct property name
        const beneficiaryPercentage =
          parseFloat(currentBeneficiary.percentage_share) || 0;
        return accumulator + beneficiaryPercentage;
      },
      0
    );

    // Update the state with the calculated total percentage
    setPercentage(totalPercentage);
  }, [beneficiaries, watchPercentage]);

  React.useEffect(() => {
    const storageData = localStorage.getItem("selected-data");

    if (storageData) {
      const $beneficiaries = JSON.parse(storageData);
      setBeneficiares($beneficiaries?.beneficiaries);
    }
  }, []);

  return (
    <>
      <CustomModal
        onClose={handleOpen}
        open={open}
        size={"lg"}
        title="Add Beneficiary"
        children={
          <>
            <ToastContainer />
            <AddBeneficiaryModal
              errors={errors}
              handleOpen={handleOpen}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              onSubmit={onSubmit}
              watch={watch}
              register={register}
            />
          </>
        }
      />

      <CustomHeader title="Beneficiaries" />
      <div className="w-full">
        <div className="flex flex-col md:flex-col lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
          <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
            <div className="w-full mr-3">
              <Input
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
                size="md"
              />
            </div>
            {!localStorage.getItem("selected-data") ? (
              <Button
                size="md"
                onClick={handleOpen}
                className="w-full text-sm font-medium capitalize bg-brand-red lg:w-1/4"
              >
                add new beneficiary
              </Button>
            ) : null}
          </div>
        </div>
      </div>

      <div className="mt-10">
        <CardBody className="overflow-x-scroll px-0  rounded-3xl bg-white">
          <Typography
            variant="h6"
            color="black"
            className="px-4 pb-4 text-sm font-bold text-black leading-none opacity-70 capitalize"
          >
            Total percentage added
            <span className="text-brand-red font-bold">{` (${percentage}) `}</span>{" "}
          </Typography>
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item, index) => {
                const isLast = index === beneficiaries?.length - 1;
                const classes = isLast
                  ? "p-2  text-center"
                  : "p-2 border-b border-blue-gray-50 text-center";

                return (
                  <tr
                    key={item?.first_name + index.toString()}
                    className=" hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.first_name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.last_name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.house_street
                          ? item?.house_street
                          : item?.location}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.email}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.location}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.percentage_share}
                      </Typography>
                    </td>

                    {!localStorage.getItem("selected-data") ? (
                      <td className={classes}>
                        <Tooltip content="Remove">
                          <IconButton
                            variant="text"
                            onClick={() => handleRemoveBeneficiary(index)}
                          >
                            <AiOutlineDelete size={18} color="red" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    ) : (
                      <td className={classes}>
                        <Button color="white" variant="text">
                          {""}
                        </Button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {paginatedData?.length < 1 && (
            <div className="mt-10 flex items-center justify-center">
              <Typography variant="h6">No data found</Typography>
            </div>
          )}
        </CardBody>

        {/* Paginator */}
        {paginatedData?.length > 0 && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="outlined"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-gray-900">{activePage}</strong> of{" "}
                <strong className="text-gray-900">
                  {Math.ceil(
                    (beneficiaries?.filter(filterRentals).length || 0) /
                      PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="outlined"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (beneficiaries?.filter(filterRentals).length || 0) /
                      PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}

        {!localStorage.getItem("selected-data") ? (
          <div className="flex flex-end justify-end my-6 mr-3">
            <Button
              disabled={percentage < 100}
              size="sm"
              className="font-normal text-sm capitalize bg-brand-blue"
              onClick={onApplicationSubmit}
            >
              {loading ? <Spinner className="w-4 h-4" /> : "Submit application"}
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BeneficiaryView;
